<template>
  <ReportDataTable :urls="urls" :page="page" />
</template>

<script>
import ReportDataTable from "@/own/components/reports/ReportDataTable.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "ReportOrderCount",
  components: { ReportDataTable },
  data: () => ({
    urls: {
      data_url: "/reports/orderCount",
      show_url: "/reports/orderCount/show",
    },
    page: "orderCount",
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
};
</script>
